import React from "react";

const WhyChooseUScomponent = () => {
    const sections = [
        {
            id: 1,
            icon: "fas fa-chart-line",
            title: "Proven Results",
            description:
                "We have a track record of delivering tangible results for our clients. From increasing website traffic to improving search rankings, we consistently deliver outcomes.",
        },
        {
            id: 2,
            icon: "fas fa-cogs",
            title: "Customized Solutions",
            description:
                "We believe that one size does not fit all when it comes to digital marketing. That's why we take the time to understand your unique needs before recommending solutions.",
        },
        {
            id: 3,
            icon: "fas fa-headset",
            title: "Dedicated Support",
            description:
                "Your success is our top priority. That's why we provide dedicated support and guidance every step of the way in optimizing and running your SEO strategy.",
        },
        {
            id: 4,
            icon: "fas fa-rocket",
            title: "Accelerated Growth",
            description:
                "Our tailored strategies help businesses achieve faster growth with high-impact digital marketing strategies and performance tracking.",
        },
        {
            id: 5,
            icon: "fas fa-lightbulb",
            title: "Innovative Ideas",
            description:
                "We bring creative and innovative solutions that challenge conventional approaches, ensuring that your brand stands out in a crowded marketplace.",
        },
        {
            id: 6,
            icon: "fas fa-users",
            title: "Team Collaboration",
            description:
                "We believe in a collaborative approach. Our team works closely with yours to ensure seamless execution of every campaign and initiative.",
        },
    ];
    return (
        <>
            <div className="why-choose-us-section">
                <div className="container-lg">
                    {/* <div className='fs-1 fw-bold'>Why Choose Us</div> */}
                    <div
                        style={{
                            background: "rgb(248 234 243)",
                            width: "fit-content",
                        }}
                    >
                        <div className="rounded px-3 py-1 bg-light header-text my-3">
                            Why Choose Us
                        </div>
                    </div>
                    <div className="fs-1 fw-bold">
                        Experience the Advantage Why We're the Right Choice
                    </div>
                    <div className="">
                        At XCreative Hub we understand that you have many
                        options when it comes to digital marketing services.
                        <br /> So why should you choose us? Here are a few
                        reasons
                    </div>
                </div>
                <div className="why-choose-us container-lg">
                    {sections.map((section) => (
                        <div className="works-content-box" key={section.id}>
                            <div className="icon">
                                <i className={`${section.icon} fa-3x`}></i>
                            </div>
                            <div className="content">
                                <h3>{section.title}</h3>
                                <p>{section.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default WhyChooseUScomponent;
