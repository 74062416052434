import React from 'react'
import './HomePage.css';
import ProjectImage from "../../assests/imgs/image.png"


const projects = [
    {
      name: 'Project Name (Proven Track Record)',
      techStacks: ['HTML', 'CSS', 'JavaScript', 'React Js', 'PHP', 'HTML', 'CSS', 'JavaScript', 'React Js', 'PHP'],
      description: "We've helped businesses of all sizes achieve their digital goals with our data-driven strategies and personalized approach.We've helped businesses of all sizes achieve their digital goals with our data-driven strategies and personalized approach.We've helped businesses of all sizes achieve their digital goals with our data-driven strategies and personalized approach.",
      image: ProjectImage
    },
    {
      name: 'Project Name (Proven Track Record)',
      techStacks: ['HTML', 'CSS', 'JavaScript', 'React Js', 'PHP', 'HTML', 'CSS', 'JavaScript', 'React Js', 'PHP'],
      description: "We've helped businesses of all sizes achieve their digital goals with our data-driven strategies and personalized approach.We've helped businesses of all sizes achieve their digital goals with our data-driven strategies and personalized approach.We've helped businesses of all sizes achieve their digital goals with our data-driven strategies and personalized approach.",
      image: ProjectImage
    },
    {
      name: 'Project Name (Proven Track Record)',
      techStacks: ['HTML', 'CSS', 'JavaScript', 'React Js', 'PHP', 'HTML', 'CSS', 'JavaScript', 'React Js', 'PHP'],
      description: "We've helped businesses of all sizes achieve their digital goals with our data-driven strategies and personalized approach.We've helped businesses of all sizes achieve their digital goals with our data-driven strategies and personalized approach.We've helped businesses of all sizes achieve their digital goals with our data-driven strategies and personalized approach.",
      image: ProjectImage
    }
];
const OurProjects = () => {

  return (
    <>
        <div className='our-project-section mt-5 pt-5'>
            <div className='container-lg'>
                <div className='d-flex justify-content-center'>
                    <div style={{background: "rgb(248 234 243)", width: "fit-content"}}>
                        <div className="rounded px-3 py-1 bg-light first-header-text">
                            Our Projects
                        </div>
                    </div>
                </div>
                <div className='text-center my-5 lh-1 fw-bold' style={{fontSize: "58px"}}>
                    The Work That Drives <br />
                    Brands Forward
                </div>
            </div>
            <div className='container-lg my-5'>
                {projects.map((project, index) => (
                    <div key={index} className='row p-4 border shadow-lg my-5' style={{ borderRadius: "20px" }}>
                    <div className='col-md-6 col-12'>
                        <div className='project-name fs-3 fw-bold'>{project.name}</div>
                        <div className='project-tech-stacks d-flex flex-wrap gap-2 my-4'>
                        {project.techStacks.map((tech, techIndex) => (
                            <div key={techIndex} className='px-2 py-1 border me-2 bg-body-secondary tech-stacks-hover' style={{ borderRadius: "15px" }}>
                            {tech}
                            </div>
                        ))}
                        </div>
                        <div className='project-description'>
                        {project.description}
                        </div>
                        <div className='view-project-btn mt-4'>
                            <button type="button" className="btn btn-lg common-gradient-background text-light rounded me-2 mb-5 mb-lg-0" style={{ fontWeight: "500" }}>
                                View More{" "}
                                <i className="fa-solid fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                    <div className='col-md-1 col-12'></div>
                    <div className='col-md-5 col-12 border rounded p-2'>
                        <img className='img-fluid' style={{ height: "400px", objectFit: "contain" }} src={project.image} alt={project.name} />
                    </div>
                    </div>
                ))}
            </div>
        </div>
    </>
  )
}

export default OurProjects
