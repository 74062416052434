import React, { useState, useEffect } from 'react';

const CounterComponent = ({ label, endValue, intervalSpeed, startValue = 0 }) => {
    const [count, setCount] = useState(startValue);

    useEffect(() => {
      let currentCount = startValue;
  
      const increment = () => {
        if (currentCount < endValue) {
          currentCount++;
          setCount(currentCount);
        }
      };
  
      const intervalId = setInterval(increment, intervalSpeed);
  
      return () => clearInterval(intervalId); // Clean up interval on unmount
    }, [endValue, intervalSpeed, startValue]);
  return (
    <>
       <div className="text-center p-4">
      <div className="display-3 font-weight-bold text-light">{count}+</div>
      <div className="h5 text-light">{label}</div>
    </div>
    </>
  )
}

export default CounterComponent
