import React from 'react';
import ServiceImage from '../../assests/imgs/service-img.png';

const services = [
    {
      id: 1,
      title: 'Social Media Marketing',
      description: 'Build a strong brand presence & engage with your audience on social media platforms. We create strategic social media campaigns.',
      bgColor: '#FFE8A4',
      image: ServiceImage,
    },
    {
      id: 2,
      title: 'Search Engine Optimization',
      description: 'Enhance your online visibility & drive organic traffic with our advanced SEO techniques. We ptimize your website to rank higher.',
      bgColor: '#CAC6FD',
      image: ServiceImage,
    },
    {
      id: 3,
      title: 'Website Design & Development',
      description: 'Make a lasting impression with professionally designed and user-friendly websites. Our web design and development services create websites that deliver.',
      bgColor: '#D4FFD6',
      image: ServiceImage,
    },
    {
      id: 4,
      title: 'Content Creation',
      description: 'Craft engaging and high-quality content that resonates with your target audience, driving more interaction and conversions.',
      bgColor: '#FFE1F3',
      image: ServiceImage,
    },
    {
      id: 5,
      title: 'Email Marketing',
      description: 'Reach your audience directly through effective email marketing campaigns that nurture leads and drive sales.',
      bgColor: '#D4E9FF',
      image: ServiceImage,
    },
    {
        id: 6,
        title: 'Pay-Per-Click Advertising',
        description: 'Maximize ROI with targeted pay-per-click (PPC) campaigns across Google Ads and social media platforms. Drive traffic and conversions quickly.',
        bgColor: '#FFDBB5',
        image: ServiceImage,
    },
];

const OurServicesSection = () => {
    return (
        <>
            <div className='our-services-section my-5 py-2'>
                <div className='container-lg'>
                    <div className="row my-5">
                        <div className="col-lg-12">
                            <div className="d-lg-flex justify-content-lg-between align-items-lg-center">
                            <div className="heading20">
                                <div style={{background: "rgb(248 234 243)", width: "fit-content"}}>
                                        <div className="rounded px-3 py-1 bg-light our-services-header my-3">
                                            Our Services
                                        </div>
                                </div>
                                <div className="fs-1 fw-bold lh-1">Tailored Solutions For 
                                    <br className="d-md-block d-none" /> Every Brand</div>
                            </div>
                            <div>
                                {/* <a href="service1.html" className="header-btn21">View All Service <i className="fa-solid fa-arrow-right"></i></a> */}
                                <button type="button" className="btn btn-lg common-gradient-background text-light rounded me-2" style={{fontWeight: "500"}}>
                                    View All Service{" "}
                                        <i className="fa-solid fa-arrow-right"></i>
                                </button>
                            </div>
                            </div>
                        </div>
                    </div>

                 
                    <div className='row my-5'>
                        {services.map(service => (
                            <div className="col-lg-4 col-md-6 my-2" key={service.id}>
                                <div className="service-boxarea p-4 rounded" style={{ backgroundColor: service.bgColor }}>
                                    <div className='fs-3 fw-bold'>{String(service.id).padStart(2, '0')}</div>
                                    <div className="img p-2">
                                        <img src={service.image} className="img-fluid" alt={service.title} />
                                    </div>
                                    <div className="content-area">
                                        <div className="fw-bold fs-5">{service.title}</div>
                                        <div className="services-desc my-1">{service.description}</div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default OurServicesSection
