import React, {useState} from 'react';
import TeamDummyImage from "../../assests/imgs/team-img.png"

const OurTeam = () => {
    const [hoveredIndex, setHoveredIndex] = useState(null); // To track which card is hovered
  
    const teamMembers = [
        {
          name: "Krystal Hermann",
          designation: "CEO & Founder",
          image: TeamDummyImage,
          socialLinks: {
            facebook: "https://facebook.com/krystal",
            instagram: "https://instagram.com/krystal",
            linkedin: "https://linkedin.com/in/krystal",
            youtube: "https://youtube.com/krystal"
          }
        },
        {
          name: "John Doe",
          designation: "CTO & Co-Founder",
          image: TeamDummyImage,
          socialLinks: {
            facebook: "https://facebook.com/john",
            instagram: "https://instagram.com/john",
            linkedin: "https://linkedin.com/in/john",
            youtube: "https://youtube.com/john"
          }
        },
        {
          name: "Jane Smith",
          designation: "CFO",
          image: TeamDummyImage,
          socialLinks: {
            facebook: "https://facebook.com/jane",
            instagram: "https://instagram.com/jane",
            linkedin: "https://linkedin.com/in/jane",
            youtube: "https://youtube.com/jane"
          }
        }
      ];

  return (
    <>
        <div className='my-5 pt-5 our-team-section'>
            <div className='container-lg'>
                <div className='d-flex justify-content-center'>
                    <div style={{background: "rgb(248 234 243)", width: "fit-content"}}>
                        <div className="rounded px-3 py-1 bg-light first-header-text">
                            Our Projects
                        </div>
                    </div>
                </div>
                <div className='text-center my-2 lh-1 fw-bold' style={{fontSize: "48px"}}>
                    Our Team: Where CreativityMeets Strategy
                </div>
            </div>
            <div className='container-lg'>
                <div className='row'>
                {teamMembers.map((member, index) => (
                    <div
                    key={index}
                    className="col-lg-4 col-md-6"
                    onMouseEnter={() => setHoveredIndex(index)}
                    onMouseLeave={() => setHoveredIndex(null)}
                    >
                    <div className="team13-boxarea bg-body-tertiary py-4">
                        <div className="img-area text-center">
                        <img
                            src={member.image}
                            className="img-fluid"
                            style={{ height: '400px', objectFit: 'contain' }}
                            alt={member.name}
                        />
                        </div>
                        <div className="text-center my-2">
                        <div className="team-member-name fs-4 fw-bold mt-2">{member.name}</div>
                        <div className="degisnation">{member.designation}</div>
                        </div>
                        {/* Social Icons - This will animate on hover */}
                        <div className={`social-icons ${hoveredIndex === index ? 'show' : ''}`}>
                        <div className='common-gradient-background px-2 py-3'>
                            {member.socialLinks.facebook && (
                            <div className='rounded-circle text-center my-1 p-3 cursor-pointer' style={{ background: "rgba(255, 255, 255, 0.2)" }}>
                                <a href={member.socialLinks.facebook} target="_blank" rel="noopener noreferrer">
                                <i className="fa-brands fs-5 text-light fa-facebook-f"></i>
                                </a>
                            </div>
                            )}
                            {member.socialLinks.instagram && (
                            <div className='rounded-circle text-center my-1 p-3 cursor-pointer' style={{ background: "rgba(255, 255, 255, 0.2)" }}>
                                <a href={member.socialLinks.instagram} target="_blank" rel="noopener noreferrer">
                                <i className="fa-brands fs-5 text-light fa-instagram"></i>
                                </a>
                            </div>
                            )}
                            {member.socialLinks.linkedin && (
                            <div className='rounded-circle text-center my-1 p-3 cursor-pointer' style={{ background: "rgba(255, 255, 255, 0.2)" }}>
                                <a href={member.socialLinks.linkedin} target="_blank" rel="noopener noreferrer">
                                <i className="fa-brands fs-5 text-light fa-linkedin-in"></i>
                                </a>
                            </div>
                            )}
                            {member.socialLinks.youtube && (
                            <div className='rounded-circle text-center my-1 p-3 cursor-pointer' style={{ background: "rgba(255, 255, 255, 0.2)" }}>
                                <a href={member.socialLinks.youtube} target="_blank" rel="noopener noreferrer">
                                <i className="fa-brands fs-5 text-light fa-youtube"></i>
                                </a>
                            </div>
                            )}
                        </div>
                        </div>
                    </div>
                    </div>
                ))}
                </div>
            </div>
        </div>
    </>
  )
}

export default OurTeam
