import React, {useState} from "react";
import RightImage from '../../assests/imgs/header-img1.png';

const FirstHeroSection = () => {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <>
            <div className="first-section-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="first-header heading19">
                                <div style={{background: "rgb(248 234 243)", width: "fit-content"}}>
                                    <div className="rounded px-3 py-1 bg-light first-header-text my-3">
                                        Top #1 SEO & Advertising Agency
                                    </div>
                                </div>
                                <div className="fw-bold" style={{ fontSize: "58px", lineHeight: "initial" }} >
                                    We Build Campaigns That Make Brands
                                    Unforgettable
                                </div>
                                <div className="my-3 fs-5" style={{color: "#5C6365"}}>
                                    At our agency, we believe that creativity
                                    has the power to transform brands and
                                    connect them with their audiences in
                                    meaningful ways.
                                </div>
                                <div className="">
                                    <button type="button" className="btn btn-lg common-gradient-background text-light rounded me-2" style={{fontWeight: "500"}}>
                                        Our Success Stories{" "}
                                        <i className="fa-solid fa-arrow-right"></i>
                                    </button>
                                    <button type="button"
                                      className={`btn btn-lg ${isHovered ? 'common-gradient-background text-light' : ''}  rounded me-2 contact-us-button`}
                                      style={{ fontWeight: '500' }}
                                      onMouseEnter={() => setIsHovered(true)} 
                                      onMouseLeave={() => setIsHovered(false)} 
                                    >
                                        Contact Us <i className="fa-solid fa-arrow-right"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-1"></div>
                        <div className="col-lg-5">
                            <div className="img1">
                                <img className="img-fluid"
                                    src={RightImage}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FirstHeroSection;
