import React from "react";

const ContactUs = () => {
    return (
        <>
            <div className="contact-us-section pt-5 bg-body-tertiary">
                <div className="container-lg py-5">
                    <div className="row my-5">
                        <div className="col-lg-12">
                            <div className="d-lg-flex justify-content-lg-between align-items-lg-center">
                                <div className="heading20">
                                    <div
                                        style={{
                                            background: "rgb(248 234 243)",
                                            width: "fit-content",
                                        }}
                                    >
                                        <div className="rounded px-3 py-1 bg-light first-header-text my-3">
                                            Contact Us
                                        </div>
                                    </div>
                                    <div className="fs-1 fw-bold lh-1">
                                        Get In Touch With Us Today
                                    </div>
                                </div>
                                
                                <div>
                                    {/* <a href="service1.html" className="header-btn21">View All Service <i className="fa-solid fa-arrow-right"></i></a> */}
                                    <button
                                        type="button"
                                        className="btn btn-lg common-gradient-background text-light rounded me-2"
                                        style={{ fontWeight: "500" }}
                                    >
                                        Get In Touch{" "}
                                        <i className="fa-solid fa-arrow-right"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                                {/* <div className="text-center fs-5 my-3">We're here to help! If you have any
                                            questions or would like to discuss{" "}
                                             how
                                            our SEO and digital marketing
                                            services can benefit your business,
                                </div> */}
                    </div>
                    <div class="contact-section sp6">
                        <div class="container">
                            {/* <div class="row">
                                <div class="col-lg-12 m-auto">
                                    <div class="contact-header-area text-center heading2">
                                        <img
                                            src="assets/img/elements/star2.png"
                                            alt=""
                                            class="star2 keyframe5"
                                        />
                                        <img
                                            src="assets/img/elements/star2.png"
                                            alt=""
                                            class="star3 keyframe5"
                                        />
                                        <h2 class="text-anime-style-3">
                                            Get In Touch With Us Today
                                        </h2>
                                        <p>
                                            We're here to help! If you have any
                                            questions or would like to discuss{" "}
                                            <br class="d-md-block d-none" /> how
                                            our SEO and digital marketing
                                            services can benefit your business,
                                        </p>
                                    </div>
                                </div>
                            </div> */}
                            <div class="row">
                                <div class="col-lg-5">
                                    <div class="contact-info-area bg-dark">
                                        <div className="fs-4 fw-bold text-light">
                                            Contact Info
                                        </div>
                                        <p className="text-light">
                                            We're here to help! If you have any
                                            questions or would like to discuss
                                            how our SEO and digital marketing
                                            services can benefit your business,
                                        </p>
                                        <div class="space32"></div>
                                        <div class="contact-auhtor-box">
                                            <div class="icons">
                                                <i class="fa fa-location text-light fs-4"></i>
                                            </div>
                                            <div class="content my-3">
                                                <div className="text-light fs-5">
                                                    Our Location
                                                </div>
                                                <a
                                                    href="#"
                                                    className="text-light"
                                                >
                                                    8708 Technology Forest Pl
                                                    Suite{" "}
                                                    <br class="d-lg-block d-none" />{" "}
                                                    125 -G, The Woodlands, TX
                                                    773
                                                </a>
                                            </div>
                                        </div>
                                        <div class="contact-auhtor-box">
                                            <div class="icons">
                                                <i class="fa fa-phone text-light fs-4"></i>
                                            </div>
                                            <div class="content my-3">
                                                <div className="text-light fs-5">
                                                    Phone Number
                                                </div>
                                                <a
                                                    href="tel:123-456-7890"
                                                    className="text-light"
                                                >
                                                    123-456-7890 <br />
                                                    123-456-7890
                                                </a>
                                            </div>
                                        </div>
                                        <div class="contact-auhtor-box">
                                            <div class="icons">
                                                <i class="fa fa-envelope text-light fs-4"></i>
                                            </div>
                                            <div class="content my-3">
                                                <div className="text-light fs-5">
                                                    Email Address
                                                </div>
                                                <a
                                                    href="mailto:infoseoc@.gmail.com"
                                                    className="text-light"
                                                >
                                                    infoseoc@.gmail.com <br />
                                                    infoseoc@.gmail.com
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-7">
                                    <div class="contact-boxarea bg-body">
                                        <div className="fs-2 fw-bold">Get In Touch</div>
                                        <p>
                                            We're here to help! If you have any
                                            questions or would like to discuss{" "}
                                            <br class="d-lg-block d-none" /> how
                                            our SEO and digital marketing
                                            services can benefit your business,
                                        </p>
                                        <form
                                            action=""
                                            method="POST"
                                        >
                                            <div class="row">
                                                <div class="col-lg-6">
                                                    <div class="input-area">
                                                        <input
                                                            type="text"
                                                            placeholder="First Name"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="input-area">
                                                        <input
                                                            type="text"
                                                            placeholder="Last Name"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-lg-6">
                                                    <div class="input-area">
                                                        <input
                                                            type="email"
                                                            placeholder="Email Address"
                                                            required
                                                        />
                                                    </div>
                                                </div>

                                                <div class="col-lg-6">
                                                    <div class="input-area">
                                                        <input
                                                            type="number"
                                                            placeholder="Phone Number"
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div class="col-lg-12"></div>
                                                <div class="col-lg-12">
                                                    <div class="input-area">
                                                        <textarea
                                                            placeholder="Your Message"
                                                            required
                                                        ></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-lg-12 text-center">
                                                    <div class="input-area">
                                                        <button type="submit" className="btn btn-lg common-gradient-background text-light rounded me-2 my-2 fs-4" style={{fontWeight: "500"}}>
                                                            Free Consultation 
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ContactUs;
