import React from "react";
import "./AboutUsComponents.css";

const OurValuesComponent = () => {
    const history = [
        {
            year: 2015,
            title: "Inception and Founding",
            description:
                "SEOC was founded with a vision to revolutionize the digital marketing industry and provide innovation.",
        },
        {
            year: 2016,
            title: "Expansion and Growth",
            description:
                "The team expanded to include additional members with diverse skill sets and expertise allowing us.",
        },
        {
            year: 2017,
            title: "Recognition and Awards",
            description:
                "We were honored to be recognized as a leader in the digital marketing space and received accolades from customers.",
        },
        {
            year: 2018,
            title: "Launch of New Services",
            description:
                "Building on our success, SEOC expanded our service offerings to better meet the evolving needs.",
        },
        {
            year: 2019,
            title: "Strategic Partnerships",
            description:
                "SEOC formed strategic partnerships and collaborations with industry leaders and technology providers.",
        },
        {
            year: 2020,
            title: "Continued Growth & Success",
            description:
                "2020 continued to experience steady growth and success, despite ongoing challenges in the external environment.",
        },
    ];

    return (
        <>
            <div className="our-values my-5">
                <div className="container-lg">
                    <div
                        style={{
                            background: "rgb(248 234 243)",
                            width: "fit-content",
                        }}
                    >
                        <div className="rounded px-3 py-1 bg-light header-text my-3">
                            Our Value
                        </div>
                    </div>
                    <div className="fs-1 fw-bold">Our Journey: Charting the <br class="d-lg-block d-none" /> Evolution of SEOC</div>
                </div>
            </div>
            <div className="history-section container-lg">
                <div className="row align-items-center">
                    {/* <div className="col-lg-6">
                        <div className="about-all-images-area">
                            <img
                                src="https://via.placeholder.com/500x300.png?text=Element+14"
                                alt=""
                                className="elements12 keyframe5"
                            />
                            <img
                                src="https://via.placeholder.com/500x300.png?text=Element+15"
                                alt=""
                                className="elements13 keyframe5"
                            />
                            <div className="row">
                                <div className="col-12 my-2">
                                    <div className="img2 image-anime">
                                        <img
                                            src="https://via.placeholder.com/500x300.png?text=History+Image+2"
                                            alt="History Image 2"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6  my-2">
                                    <div className="img1 image-anime">
                                        <div className="space100"></div>
                                        <img
                                            src="https://via.placeholder.com/500x300.png?text=History+Image+2"
                                            alt="History Image 1"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6 my-2">
                                    <div className="img1 image-anime">
                                        <div className="space100"></div>
                                        <img
                                            src="https://via.placeholder.com/500x300.png?text=History+Image+2"
                                            alt="History Image 1"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div className="col-12">
                        <div className="history-content-area">
                            <div className="row">
                                {history.map((item, index) => (
                                    <div
                                        className="col-lg-6 col-md-6"
                                        key={index}
                                    >
                                        <div className="history-box-content shadow p-2 rounded">
                                            <h5>{item.year}</h5>
                                            <div className="history-title">
                                                {item.title}
                                            </div>
                                            <p>{item.description}</p>
                                            <button className="btn readmore border rounded">
                                                Read More{" "}
                                                <i className="fa-solid fa-arrow-right"></i>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OurValuesComponent;
