import React from 'react';
import LeftAboutUsImage from "../../assests/imgs/image.png";

const SecondAboutUSSection = () => {
  return (
    <>
        {/* <div className="about13-section-area sp1">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="images-area" data-aos-duration="1000">
                            <div className="img1">
                                <img src="assets/img/all-images/new-img/about-img1-h13.png" alt="" />
                            </div>
                            <div className="img2">
                                <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 288 288">
                                    <linearGradient id="imagewave" x1="70.711%" x2="0%" y1="70.711%" y2="0%">
                                    <stop className="stop-color2" offset="0%" stop-opacity="1"></stop>
                                    <stop className="stop-color1" offset="100%" stop-opacity="1"></stop>
                                    </linearGradient>
                                    <path fill="url(#imagewave)">
                                    
                                    <animate repeatCount="indefinite" attributeName="d" dur="5s" values="M37.5,186c-12.1-10.5-11.8-32.3-7.2-46.7c4.8-15,13.1-17.8,30.1-36.7C91,68.8,83.5,56.7,103.4,45
                                    c22.2-13.1,51.1-9.5,69.6-1.6c18.1,7.8,15.7,15.3,43.3,33.2c28.8,18.8,37.2,14.3,46.7,27.9c15.6,22.3,6.4,53.3,4.4,60.2
                                    c-3.3,11.2-7.1,23.9-18.5,32c-16.3,11.5-29.5,0.7-48.6,11c-16.2,8.7-12.6,19.7-28.2,33.2c-22.7,19.7-63.8,25.7-79.9,9.7
                                    c-15.2-15.1,0.3-41.7-16.6-54.9C63,186,49.7,196.7,37.5,186z;
                                    
                                    
                                    M51,171.3c-6.1-17.7-15.3-17.2-20.7-32c-8-21.9,0.7-54.6,20.7-67.1c19.5-12.3,32.8,5.5,67.7-3.4C145.2,62,145,49.9,173,43.4
                                    c12-2.8,41.4-9.6,60.2,6.6c19,16.4,16.7,47.5,16,57.7c-1.7,22.8-10.3,25.5-9.4,46.4c1,22.5,11.2,25.8,9.1,42.6
                                    c-2.2,17.6-16.3,37.5-33.5,40.8c-22,4.1-29.4-22.4-54.9-22.6c-31-0.2-40.8,39-68.3,35.7c-17.3-2-32.2-19.8-37.3-34.8
                                    C48.9,198.6,57.8,191,51,171.3z;
                                    
                                    M37.5,186c-12.1-10.5-11.8-32.3-7.2-46.7c4.8-15,13.1-17.8,30.1-36.7C91,68.8,83.5,56.7,103.4,45
                                    c22.2-13.1,51.1-9.5,69.6-1.6c18.1,7.8,15.7,15.3,43.3,33.2c28.8,18.8,37.2,14.3,46.7,27.9c15.6,22.3,6.4,53.3,4.4,60.2
                                    c-3.3,11.2-7.1,23.9-18.5,32c-16.3,11.5-29.5,0.7-48.6,11c-16.2,8.7-12.6,19.7-28.2,33.2c-22.7,19.7-63.8,25.7-79.9,9.7
                                    c-15.2-15.1,0.3-41.7-16.6-54.9C63,186,49.7,196.7,37.5,186z  "></animate>
                                    
                                    </path>
                                    
                                    </svg>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about13-header heading20">
                            <h5 data-aos="fade-left" data-aos-duration="800"><img src="assets/img/icons/logo-icons8.svg" alt="" />About us</h5>
                            
                            <h2 className="text-anime-style-3">The Minds Behind Your Brand’s Success</h2>
                            
                            <p data-aos="fade-left" data-aos-duration="1000">At the heart of our agency is a passion for creativity and a relentless drive to push boundaries. We are a team of thinkers, dreamers, and doers who specialize in crafting unique strategies bring brands.</p>
                            <div className="row">
                                <div className="col-lg-6 col-md-6" data-aos="zoom-in" data-aos-duration="900">
                                    <div className="about-icons">
                                            <div className="icons">
                                                <img src="assets/img/icons/about1-h13.svg" alt="" />
                                            </div>
                                            <a href="about.html">Our Story Creative Innovation</a>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6" data-aos="zoom-in" data-aos-duration="1000">
                                    <div className="about-icons">
                                            <div className="icons">
                                                <img src="assets/img/icons/about2-h13.svg" alt="" />
                                            </div>
                                            <a href="about.html">Driven Creativity Focused </a>
                                    </div>
                                </div>
                            </div>
                            <div className="space24"></div>
                            <p data-aos="fade-left" data-aos-duration="1100">Our mission is simple: to help brands stand in a crowded market & connect with their audience in meaningful, lasting ways. We’re not just an agency.</p>
                            <div className="space32"></div>
                            <div className="btn-area1" data-aos="fade-left" data-aos-duration="1200">
                            <a href="contact.html" className="header-btn21">Get Free Quote <i className="fa-solid fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        <div className="about-section-area my-5">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="images-area" data-aos="zoom-in" data-aos-duration="1000">
                            <img src={LeftAboutUsImage} className='img-fluid' alt='Left Image'/>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="about13-header heading20">
                            <div style={{background: "rgb(248 234 243)", width: "fit-content"}}>
                                    <div className="rounded px-3 py-1 bg-light about-us-header-text my-3">
                                        About us
                                    </div>
                                </div>
                            
                            <div className="fw-bold my-2" style={{ fontSize: "48px", lineHeight: "initial" }}>
                                The Minds Behind Your Brand’s Success
                            </div>
                            
                            <div className="my-3 fs-5" style={{color: "#5C6365"}}>At the heart of our agency is a passion for creativity and a relentless drive to push boundaries. We are a team of thinkers, dreamers, and doers who specialize in crafting unique strategies bring brands.</div>
                            <div className="row">
                                <div className="col-lg-6 col-md-6">
                                    <div className="about-icons">    
                                        <div className='fw-bold px-2 py-3 text-center fs-5 text-dark' style={{background: "rgb(248 234 243 / 41%)"}}>Our Story Creative Innovation</div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="about-icons">
                                        <div className='fw-bold px-2 py-3 fs-5 text-center text-dark' style={{background: "rgb(248 234 243 / 41%)"}}>Driven Creativity Focused </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div className="my-3 fs-5" style={{color: "#5C6365"}}>Our mission is simple: to help brands stand in a crowded market & connect with their audience in meaningful, lasting ways. We’re not just an agency.</div>
                            
                            <div>
                                <button type="button" className="btn btn-lg common-gradient-background text-light rounded me-2" style={{fontWeight: "500"}}>
                                    Get Free Quote{" "}
                                            <i className="fa-solid fa-arrow-right"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default SecondAboutUSSection
