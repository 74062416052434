import React from 'react'
import ConatactUsHeader from './ContactUsComponents/ConatactUsHeader'
import MainHeaderComponent from '../layouts/MainHeaderComponent'
import FooterComponent from '../layouts/FooterComponent'
import SectiondConactUsSection from './ContactUsComponents/SectiondConactUsSection'
import LastContactUsSection from './ContactUsComponents/LastContactUsSection'

const ContactUsComponent = () => {
  return (
    <>
      <MainHeaderComponent />
      <ConatactUsHeader />
      <SectiondConactUsSection />
      <LastContactUsSection />
      <FooterComponent />
    </>
  )
}

export default ContactUsComponent
