import React from 'react'
import SendSecImg from '../../assests/imgs/about-us-section.png'

const SecondAboutUsSection = () => {
    return (
        <>
            <div class="second-about-section bg-white my-5">
                <div class="container">
                    <div class="row align-items-center">
                    <div class="col-lg-4">
                        <div class="about-images">
                        <figure class="image-anime">
                            <img src={SendSecImg} className='img-fluid' alt="" />
                        </figure>
                        </div>
                    </div>
                    <div class="col-lg-5">
                        <div class="about-content-area heading2">
                        <div className='fs-1 fw-bold'>Comprehensive SEO & Digital Marketing Solutions.</div>
                        <p>Welcome to SEOC your trusted partner for comprehensive SEO and digital marketing solutions. With our proven expertise and innovative strategies the digital landscape.</p>
                        <div class="btn-area">
                            <button type="button" className="btn btn-lg common-gradient-background text-light rounded me-2" style={{fontWeight: "500"}}>
                                Learn More{" "}
                                            <i className="fa-solid fa-arrow-right"></i>
                            </button>
                        </div>
                        </div>
                    </div>
                    <div class="col-lg-3 mt-5">
                        <div class="about-auhtor-images mt-5">
                            <figure class="image-anime">
                                <img src={SendSecImg} className='img-fluid' alt="" />
                            </figure>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
        </>
    )
}

export default SecondAboutUsSection