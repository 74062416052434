import React from "react";
import './Layout.css';
import Logo from '../assests/imgs/logo1.png';

const FooterComponent = () => {
    return (
        <>
            <div class="footer-section">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="footer-logo-area">
                                <img src={Logo} alt="" />
                                <p>
                                    By optimizing content, leveraging relevant
                                    keywords, and adhering to best practices,
                                    businesses can secure prominent position
                                    (SEO)
                                </p>
                                <ul className="ps-0">
                                    <li>
                                        <a >
                                            <i class="fa-brands fa-facebook-f"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a >
                                            <i class="fa-brands fa-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a >
                                            <i class="fa-brands fa-linkedin-in"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a >
                                            <i class="fa-brands fa-youtube"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-2 col-md-6">
                            <div class="footer-logo-area1">
                                <div className="fs-5">About</div>
                                <ul className="ps-0">
                                    <li className="list-unstyled">
                                        <a className="text-decoration-none" href="blog.html">Our Blog</a>
                                    </li>
                                    <li className="list-unstyled">
                                        <a className="text-decoration-none" href="about.html">About Us</a>
                                    </li>
                                    <li className="list-unstyled">
                                        <a className="text-decoration-none" href="service1.html">Services</a>
                                    </li>
                                    <li className="list-unstyled">
                                        <a className="text-decoration-none" href="case.html">Marketing</a>
                                    </li>
                                    <li className="list-unstyled">
                                        <a className="text-decoration-none" href="testimonials.html">
                                            Testimonials
                                        </a>
                                    </li>
                                    <li className="list-unstyled">
                                        <a className="text-decoration-none" href="contact.html">Contact Us</a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div class="col-lg-3 col-md-6">
                            <div class="footer-logo-area2">
                                <div className="fs-5">Get in touch</div>
                                <ul className="ps-0">
                                    <li  className="list-unstyled">
                                        <a href="">
                                            <img
                                                src="assets/img/icons/email.svg"
                                                alt=""
                                            />
                                            <span>Infoseoc@gmail.com</span>
                                        </a>
                                    </li>
                                    <li  className="list-unstyled">
                                        <a >
                                            <img
                                                src="assets/img/icons/location.svg"
                                                alt=""
                                            />
                                            <span>
                                                8708 Technology Forest{" "}
                                                <br class="d-lg-block d-none" />{" "}
                                                Pl Suite 125 -G, The{" "}
                                                <br class="d-lg-block d-none" />{" "}
                                                Woodlands, TX 773
                                            </span>
                                        </a>
                                    </li>
                                    <li  className="list-unstyled">
                                        <a href="tel:123-456-7890">
                                            <img
                                                src="assets/img/icons/phone.svg"
                                                alt=""
                                            />
                                            <span>123-456-7890</span>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="footer-logo-area3">
                                <div className="fs-5">Subscribe Our Newsletter</div>

                                <form action="#">
                                    <input className="border"
                                        type="text"
                                        placeholder="Enter Your email"
                                    />
                                    <button type="button" className="btn btn-lg common-gradient-background text-light rounded me-2 my-2" style={{fontWeight: "500"}}>
                                        Subscribe
                                        <i className="fa-solid fa-arrow-right"></i>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="space50 d-lg-block d-none"></div>
                    <div class="space40 d-lg-none d-block"></div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="copyright-area">
                                <div class="pera">
                                    <p>
                                        ⓒCopyright 2024 SEOC . All rights
                                        reserved
                                    </p>
                                </div>
                                <ul>
                                    <li>
                                        <a >Terms & Conditions</a>
                                    </li>
                                    <li>
                                        <a  class="m-0">
                                            {" "}
                                            Privacy Policy{" "}
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default FooterComponent;
