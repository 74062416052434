// import logo from './logo.svg';
import './App.css';
// import MainHeaderComponent from './layouts/MainHeaderComponent';
// import FirstSection from './components/HomepageComponents/FirstHeroSection';
import HomepageComponent from './components/HomepageComponent';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import AboutUSComponent from './components/AboutUSComponent';
import ContactUsComponent from './components/ContactUsComponent';


function App() {
  return (

    <div className="App figtree-font-family">
    {/* <div className="App roboto-regular"> */}
    {/* For live */}
    {/* <BrowserRouter basename="/frontend"> */}
    {/* For local */}
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomepageComponent />} />
          <Route path="/about-us" element={<AboutUSComponent />} />
          <Route path="/contact-us" element={<ContactUsComponent />} />
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
