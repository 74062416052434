import React from 'react'
import FirstHeroSection from './HomepageComponents/FirstHeroSection'
import MainHeaderComponent from '../layouts/MainHeaderComponent'
import './HomepageComponents/HomePage.css';
import SecondAboutUSSection from './HomepageComponents/SecondAboutUSSection';
import OurServicesSection from './HomepageComponents/OurServicesSection';
import ContinuousScroll from './HomepageComponents/ContinuousScroll';
import OurProjects from './HomepageComponents/OurProjects';
import OurTeam from './HomepageComponents/OurTeam';
import FooterComponent from '../layouts/FooterComponent';
import ContactUs from './HomepageComponents/ContactUs';

const HomepageComponent = () => {
  return (
    <>
      <MainHeaderComponent />
      <FirstHeroSection />
      <SecondAboutUSSection />
      <OurServicesSection />
      {/* <ContinuousScroll /> */}
      <OurProjects />
      <OurTeam />
      <ContactUs />
      <FooterComponent />
    </>
  )
}

export default HomepageComponent
