import React, { useEffect } from 'react';
import "./AboutUsComponents.css";

const OurSkillsSection = () => {
  const skills = [
    { name: 'S M M', percentage: 82 },
    { name: 'P P C', percentage: 60 },
    { name: 'SEO', percentage: 99 },
    { name: 'Digital Marketing', percentage: 95 },
  ];

  // Function to trigger the animations after the component is mounted
  const animateProgressBars = () => {
    const circles = document.querySelectorAll('.circle');

    circles.forEach(circle => {
      const canvas = circle.querySelector('canvas');
      const ctx = canvas.getContext('2d');
      const percentage = parseInt(circle.getAttribute('data-percent'));

      const radius = 80; // Increase radius for a larger circle
      const circumference = 2 * Math.PI * radius;

      // Draw background circle (gray)
      ctx.beginPath();
      ctx.arc(85, 85, radius, 0, 2 * Math.PI);
      ctx.strokeStyle = '#222326';
      ctx.lineWidth = 12;
      ctx.stroke();

      // Draw progress circle
      const offset = circumference - (percentage / 100) * circumference;
      ctx.beginPath();
      ctx.arc(85, 85, radius, -Math.PI / 2, (-Math.PI / 2) + (2 * Math.PI * (percentage / 100)));
      ctx.strokeStyle = "#fff"; // White progress circle
      ctx.lineWidth = 10;
      ctx.stroke();
    });
  };

  useEffect(() => {
    animateProgressBars(); // Trigger the animation on component mount
  }, []);

  return (
    <div className="skills-section-area py-5">
      {/* Background Images */}
      <img src="https://xcreativehub.com/assets/img/bg/cta-bg1.png" alt="Background 1" className="cta-bg1 animation-key-2" />
      <img src="https://xcreativehub.com/assets/img/bg/cta-bg2.png" alt="Background 2" className="cta-bg2 animation-key-1" />

      <div className="container">
        <div className="row">
          <div className="col-lg-3 m-auto">
            <div className="skills-header text-center text-light">
              <div style={{ fontSize: "60px" }}>Our Skills</div>
            </div>
          </div>
        </div>

        <div className="col-lg-10" style={{margin: "3rem auto"}}>
          <div className="circle-progress-area">
            <div className="row">
              {skills.map((skill, index) => (
                <div key={index} className="col-lg-3 col-md-6">
                  <div className="progresbar">
                    <div className="progressbar">
                      <div className="circle" data-percent={skill.percentage}>
                        <canvas width="200" height="200" style={{position: "absolute", left: "32px"}}></canvas> {/* Increased canvas size */}
                        <div className='text-light'>{skill.percentage}%</div>
                      </div>
                    </div>
                    <div className='text-light fs-2 text-center'>{skill.name}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurSkillsSection;