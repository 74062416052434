import React from 'react'
import AboutUsHeader from './AboutUsComponents/AboutUsHeader'
import MainHeaderComponent from '../layouts/MainHeaderComponent'
import FooterComponent from '../layouts/FooterComponent'
import SecondAboutUsSection from './AboutUsComponents/SecondAboutUsSection'
import ThirdAboutUsSection from './AboutUsComponents/ThirdAboutUsSection'
import WhyChooseUScomponent from './AboutUsComponents/WhyChooseUScomponent'
import OurValuesComponent from './AboutUsComponents/OurValuesComponent'
import OurSkillsSection from './AboutUsComponents/OurSkillsSection'
import TestimonialSection from './AboutUsComponents/TestimonalSection'
import LastAboutUsSection from './AboutUsComponents/LastAboutUsSection'

const AboutUSComponent = () => {
  return (
    <>
        <MainHeaderComponent />
        <AboutUsHeader />
        <SecondAboutUsSection /> 
        <ThirdAboutUsSection />
        <WhyChooseUScomponent />
        <OurValuesComponent />
        <OurSkillsSection />
        <TestimonialSection />
        <LastAboutUsSection />
        <FooterComponent />
    </>
  )
}

export default AboutUSComponent
