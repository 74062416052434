import React from 'react';
import Logo from '../assests/imgs/logo1.png';
import {  Link } from "react-router-dom";

const MainHeaderComponent = () => {
  return (
    <>
        <nav className="navbar navbar-expand-lg navbar-light container-lg">
            <div className="container-fluid">
                <a className="navbar-brand" href="#">
                    <img src={Logo} alt="X Creative Hub"/>
                </a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarScroll">
                    <div className="d-lg-flex justify-content-lg-between w-100">
                        <div></div>
                        <div>
                            <ul className="navbar-nav me-auto my-2 my-lg-0 navbar-nav-scroll">
                                <li className="nav-item  px-lg-2 px-0">
                                    <Link to="/" className='nav-link text-dark'>Home</Link>
                                    {/* <a className="nav-link" style={{color: "black"}} aria-current="page">Home</a> */}
                                </li>
                                <li className="nav-item  px-lg-2 px-0">
                                    <Link to="/about-us" className='nav-link text-dark'>About Us</Link>
                                    {/* <a className="nav-link" style={{color: "black"}}>About Us</a> */}
                                </li>
                                <li className="nav-item  px-lg-2 px-0">
                                    <a className="nav-link" style={{color: "black"}} aria-disabled="true">Services</a>
                                </li>
                                <li className="nav-item  px-lg-2 px-0">
                                    <a className="nav-link" style={{color: "black"}} aria-disabled="true">Projects</a>
                                </li>
                                <li className="nav-item  px-lg-2 px-0">
                                    <Link to="/contact-us" className='nav-link text-dark'>Contact Us</Link>
                                    {/* <a className="nav-link" style={{color: "black"}} aria-disabled="true">Contact Us</a> */}
                                </li>
                            </ul>
                        </div>
                        <div>
                            <button type="button" className="btn btn-lg rounded text-light common-gradient-background" style={{fontWeight: "600"}}>
                                Get Free Quote
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </>
  )
}

export default MainHeaderComponent
