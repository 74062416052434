import React from 'react';
import dummyImage from '../../assests/imgs/team-img.png';  // Adjust path as needed

const testimonials = [
  {
    name: "John Doe",
    title: "CEO at XYZ Company",
    image: dummyImage, // Replace with actual image URL
    text: "This is the best service I have ever used. The team was professional and exceeded expectations. Highly recommended!",
    rating: 5, // Rating out of 5 stars
    reviewDate: "2024-08-15"
  },
  {
    name: "Jane Smith",
    title: "Marketing Director at ABC Corp",
    image: dummyImage, // Replace with actual image URL
    text: "Amazing experience. Their attention to detail and customer service is outstanding. I'm really impressed!",
    rating: 4.5, // Rating out of 5 stars
    reviewDate: "2024-09-12"
  },
  {
    name: "Sam Wilson",
    title: "Product Manager at DEF Ltd",
    image: dummyImage, // Replace with actual image URL
    text: "I had a great experience working with them. They helped me achieve my goals and I will definitely be coming back!",
    rating: 4, // Rating out of 5 stars
    reviewDate: "2024-09-25"
  }
];

// Function to render star ratings including half-stars
const renderStars = (rating) => {
  const fullStars = Math.floor(rating); // Number of full stars
  const hasHalfStar = rating % 1 !== 0; // Check if there's a half star
  const stars = [];

  // Render full stars
  for (let i = 0; i < fullStars; i++) {
    stars.push(<i key={`full-${i}`} className="fa fa-star text-warning"></i>);
  }

  // Render half star (if any)
  if (hasHalfStar) {
    stars.push(<i key="half" className="fa fa-star-half-o text-warning"></i>);
  }

  // Render empty stars
  for (let i = fullStars + (hasHalfStar ? 1 : 0); i < 5; i++) {
    stars.push(<i key={`empty-${i}`} className="fa fa-star-o text-warning"></i>);
  }

  return stars;
};

const TestimonialSection = () => {
  return (
    <section className="testimonial-section py-5">
      <div className="container">
        <div className="row">
          <div className="col text-center">
            <h2 className="mb-4">What Our Clients Say</h2>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {/* Bootstrap Carousel for Testimonials */}
            <div id="testimonialCarousel" className="carousel slide" data-bs-ride="carousel">
              <div className="carousel-inner">
                {testimonials.map((testimonial, index) => (
                  <div className={`carousel-item ${index === 0 ? 'active' : ''}`} key={index}>
                    <div className="testimonial-item row align-items-center p-4 bg-white rounded shadow-lg">
                      {/* Image Section (left side on larger screens) */}
                      <div className="col-md-6 mb-3 mb-md-0 d-flex justify-content-center justify-content-md-start">
                        <div className="testimonial-image-wrapper">
                          <img
                            src={testimonial.image}
                            alt={`${testimonial.name}`}
                            className="testimonial-image"
                          />
                        </div>
                      </div>
                      {/* Text/Description Section (right side on larger screens) */}
                      <div className="col-md-6 text-center text-md-start">
                        <p className="testimonial-text">{testimonial.text}</p>
                        <div className="testimonial-rating mb-3">
                          {renderStars(testimonial.rating)}
                        </div>
                        <h5 className="testimonial-name">{testimonial.name}</h5>
                        <p className="testimonial-title">{testimonial.title}</p>
                        <p className="testimonial-date text-muted">{testimonial.reviewDate}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* Carousel Controls */}
              <button className="carousel-control-prev" type="button" data-bs-target="#testimonialCarousel" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#testimonialCarousel" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
