import React from 'react'
import './AboutUsComponents.css';
import { Link } from 'react-router-dom';
const AboutUsHeader = () => {
  return (
    <>
        <div class="about-header-area">
            <div class="container py-5">
                <div class="row my-5">
                    <div class="col-lg-3 m-auto">
                        <div class="about-inner-header heading9 text-center">
                            <div className='fw-bold' style={{fontSize: "48px"}}>About Us</div>
                            <Link to="/" className='mx-2 text-dark text-decoration-none'>Home</Link>
                            <i class="fa-solid fa-angle-right"></i> 
                            <span className='fw-bold mx-2'>About Us</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  )
}

export default AboutUsHeader
