import React, {useEffect, useState} from 'react'
import CounterComponent from '../common/CounterComponent';

const ThirdAboutUsSection = () => {
 
  return (
    <>
        <div className='third-section py-5'>
            <div className='text-center fw-bold lh-1' style={{fontSize: "48px"}}>
                Experiences Fueled by <br />
                Passion and Expertise
            </div>
            <div className='text-center  my-3 w-75 mx-auto'>At our core, we're more than just a creative agency <br /> – we're a dynamic team of storytellers, strategists, and tech enthusiasts.</div>
          <div className="container-lg d-flex flex-column align-items-center justify-content-center">
        <div className="row justify-content-center w-100">
          {/* Project Completed Counter */}
          <div className="col-md-4">
            <div className="card">
              <div className="card-body common-gradient-background">
                <CounterComponent label="Years Of experience" endValue={5} intervalSpeed={100} />
              </div>
            </div>
          </div>

          <div className="col-md-4">
            <div className="card">
              <div className="card-body common-gradient-background">
                <CounterComponent label="Projects Completed" endValue={120} intervalSpeed={10} />
              </div>
            </div>
          </div>

          {/* Happy Clients Counter */}
          <div className="col-md-4">
            <div className="card">
              <div className="card-body common-gradient-background">
                <CounterComponent  label="Happy Clients"  endValue={250}  intervalSpeed={15} />
              </div>
            </div>
          </div>
        </div>
          </div>
        </div>
    </>
  )
}

export default ThirdAboutUsSection
