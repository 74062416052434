import React from 'react'

const LastContactUsSection = () => {
  return (
    <>
     <div class="cta-section-area py-5 common-gradient-background">
     <img src="https://xcreativehub.com/assets/img/bg/cta-bg1.png" alt="Background 1" className="cta-bg1 animation-key-2" />
     <img src="https://xcreativehub.com/assets/img/bg/cta-bg2.png" alt="Background 2" className="cta-bg2 animation-key-1" />
  <div class="container  py-5">
    <div class="row">
      <div class="col-lg-12 m-auto">  
        <div class="cta-header-area text-center sp4 heading2">
          <div className='text-light fs-1'>Ready To Take Your SEO To <br class="d-md-block d-none" /> The Next Level</div>
          <div className='fs-4 text-light'>Effective SEO strategies not only elevate a website's visibility but also drive <br class="d-md-block d-none" /> targeted traffic, enhance user experience,</div>
          <div class="btn-area text-center">
            {/* <a href="contact.html" class="header-btn1">Free Consultation <span><i class="fa-solid fa-arrow-right"></i></span></a> */}
          </div>
        </div>
      </div>
    </div>
  </div>
</div> 
    </>
  )
}

export default LastContactUsSection
